$main-bg-color: #202021;
$nav-bg-color: #131314;
$border-color: #81858d66;

$dis-bg-button: #3f51b580;
$dis-clr-button: #ffffff85;

$light-gray: #bebebe;
$medium-gray: #707070;
$level2-gray: #586878;
$level3-gray: #29292a;
$level4-gray: #282828;
$level5-gray: #1a1a1b;

$g-5b5b5b: #5b5b5b;

$level2-white: #dedede;

$medium-blue: #347dc7;

$level1-orange: #ff9900;

$level1-red: #ff2525;
$fs-yellow: #ffc700;
$level1-green: #04b330;

//-------------------------------

//-----------COLORS---------------
//-----------new themes-----------
$white-color: #ffffff;

$loader-color: #dadada;

$content-bg-color: #131314;
$main-bg-color: #252525;

$prm-dis-color: #4f4f4f;
$prm-dis-clr-button: #494a4c;
$prm-color: #3369ff;
$prm-selected-color: #545353;
$prm-hover-color: #214ecc;

$dng-dis-color: #ff33336b;
$dng-color: #c73232;
$dng-selected-color: #545353;
$dng-hover-color: #cc2121;

$card-bg-color: #252526;
$card-txt-color: #bebebe;

$scard-bg-color: #131314;
$scard-txt-color: #b9b9b9;
$scard-subtxt-color: #6e6e6e;

$snav-route-act-color: #2c4282;
$snav-bg-color: #373738;

$fc-border-today-color: #bbbcbf;

$input-bg: #252526;
$input-border-color: #bbbcbf;
$input-hover-color: #707070;
$input-color-label: #a8a9ac;
$input-color-txt: #dedede;

$th-border-color: #686868;
$th-border-size: 1px;
$th-backcolor: #252526;
$th-title-color: #bdbdbd;
$th-title-fsize: 18px;
$th-height: 2.4rem;
$th-padding: 8px;
$th-thead-border-color: #585858;

$td-font-size: 20px;
$td-font-weight: 400;
$td-border-color: #484848;
$td-border-size: 1px;
$td-backcolor: #000000;

$pagein-selector-backcolor: #040404;
$pagein-selector-border-color: #707070;
$pagein-selector-border-size: 1px;

$bottom-sheet-bg: #000000;

//-------------BORDERS----------------
$btn-brd-radius: 2px;
$form-field-brd-radius: 4px;
$form-field-brd-width: 1px;
$form-field-brd-color: #585858;

//-------------TYPOGRAPHY---------------
$button-fnt-weight: 400;
$button-fnt-size: 20px;
$button-line-height: 25px;

//-------------ANIMATIOS----------------
$global-transition: 300ms;

//------------Radio buttons----------
$radio-brd-color: #bdbdbd;

//------------Break point screen--------------
$small-screen: 576px;
$medium-screen: calc(768px + 1px);
$large-screen: 991px;
$extra-large-screen: 1200px;
$x-extra-large-screen: 1400px;
