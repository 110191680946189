mat-dialog-container {
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid #3369ff;
  position: relative;
  padding: 0 !important;

  .mat-mdc-dialog-surface {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mdc-dialog__surface::before {
    display: none !important;
  }

  .header-modal-content {
    position: absolute;
    top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .close-btn {
      padding: 0;
      width: 24px;
      height: 24px;
      margin-inline-end: 15px;
      min-width: unset !important;
    }

    .modal-icon {
      padding: 0 0 0 20px;
    }

    h1 {
      font-size: 28px !important;
      font-weight: 400 !important;
      text-transform: uppercase !important;
      text-align: center !important;
      border-bottom: 2px solid $prm-color;
      padding-bottom: 3px !important;
      padding-inline: 0 !important;
      margin-bottom: 0px !important;
    }

    h1::before {
      display: none;
    }

    .modal-title {
      color: white;
    }
  }

  .modal-main-div {
    max-height: 40rem;
    position: relative;

    .modal-header-div {
      width: 100%;
      top: -24px;
      padding-bottom: 14px;
      margin-top: -14px;
      margin-bottom: 18px;
      border-bottom: 2px solid $prm-color;
      position: sticky;
      left: 0;
      z-index: 10;
      background-color: black !important;
    }

    h2 {
      margin-top: 6px;
    }
  }

  .action-buttons {
    @extend .flex-center;

    position: relative;
    width: 100%;
  }

  form {
    margin-bottom: 26px;

    .toggle-switch-field {
      width: max-content;
      height: 36px;
      border-radius: 4px;
      background-color: #252526;
      border: 1px solid rgba(133, 133, 133, 0.8);
      margin-bottom: 14px;
      margin-inline-end: 18px;
      margin-inline-start: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      & > * {
        margin-inline: 4px;
      }

      i {
        height: 24px;
      }

      span {
        font-size: 18px;
        font-weight: 400;
        color: #bdbdbd;
        display: block;
        width: max-content;
      }
    }

    .toggle-switch-active {
      i svg path {
        fill: #ffc700 !important;
      }

      span {
        color: white !important;
      }
    }

    mat-form-field {
      margin: 0 8px;
      //width: 16.4rem;

      input,
      textarea {
        font-size: 17px !important;
      }

      mat-select {
        font-size: 16px !important;
      }
    }

    // .text-area-field {
    //   width: 33.7rem !important;
    // }

    .save-btn {
      font-kerning: none;
      font-weight: 400;
      font-size: 20px;
      font-style: normal;
      line-height: 25px;
      text-transform: uppercase;
      width: 172px !important;
      height: 50px !important;
    }

    .save-btn:disabled {
      color: #000000 !important;
    }

    .mat-mdc-dialog-content {
      padding: 0 !important;
      text-align: center;
      margin-top: 16px;
      width: 100%;
    }

    .mat-mdc-dialog-actions {
      padding: 12px 0;
      //padding-bottom: 48px;

      button {
        height: 50px;
        min-width: 110px !important;
      }

      .remove-btn {
        position: absolute;
        bottom: 0;
        right: 35px;
        padding: 0;
        min-width: 0 !important;

        mat-icon {
          width: 24px !important;
          height: 32px !important;
          font-size: 2.2rem;
        }
      }

      .remove-btn:hover {
        mat-icon {
          color: $prm-hover-color;
        }
      }
    }

    .form-header-controls {
      display: flex;
      justify-content: flex-start;
      padding: 15px 29px;

      button {
        font-size: 14px !important;
        width: fit-content !important;
        height: 32px !important;
        padding: 0 10px;
      }
    }

    .form-column-container {
      display: flex;
      width: 100%;
      justify-content: center;

      .form-controls-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 46.4%;

        mat-form-field {
          width: 94% !important;
        }
      }
    }

    .full-row-control {
      width: 94%;
    }

    .inline-form-control {
      padding: 10px;
      padding-top: 0px;

      mat-form-field {
        width: 48% !important;
        margin-bottom: 8px !important;
      }
    }
  }

  h1,
  button,
  span {
    color: $white-color;
  }

  p,
  li {
    color: $light-gray;
  }

  mat-checkbox {
    width: 100%;
    text-align: start;

    .mat-checkbox-label {
      font-weight: normal;
    }
  }
}

.modal-badge {
  color: $input-border-color;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  left: 30px;
  top: 4px;
}

@media (max-width: $medium-screen) {
  mat-dialog-container {
    form {
      margin-bottom: 0;

      .form-header-controls {
        padding: 14px 8px;
      }

      .header-modal-content {
        align-items: center;
      }

      .form-column-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .form-controls-column {
          width: 100%;
        }
      }

      .inline-form-control {
        flex-direction: column;
        padding-bottom: 0;

        mat-form-field {
          width: 100% !important;
          margin-bottom: 14px !important;
        }
      }

      .save-btn {
        height: 40px !important;
        width: 110px !important;
      }
    }
  }
}
