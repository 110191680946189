@import "/src/theme.scss";

:root {
  font-size: 100%;
}

@font-face {
  font-family: "DavisSans";
  src: url(./assets/fonts/en/DavisSans-Regular.otf);
}

@font-face {
  font-family: "NotoSansJP";
  src: url(./assets/fonts/ja/NotoSansJP-Regular.ttf);
}

html {
  overflow: hidden !important;
  background-color: #131314;
}

a {
  display: block;
  color: $white-color;
  text-decoration: none;
}

html,
body {
  height: 100%;
}

body {
  scroll-behavior: smooth;
  overflow-y: hidden;
  padding: 0;
  margin: 0;

  & * {
    font-family: inherit !important;
    letter-spacing: unset !important;
  }
}

h5 {
  color: $white-color;
  font-size: 1.16rem !important;
}

.modal-main-div i svg {
  width: 20px !important;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.full-heigth {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.fit-width {
  width: fit-content;
}

.sp-title {
  color: $light-gray;
  margin-inline-start: 8px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row;
}

main {
  //width: 100vw;
  height: 100vh;
  background-color: $content-bg-color;
  overflow: hidden;
}

.div-sep {
  background-color: $medium-gray;
  height: 2px;
  width: 70%;
  margin: 1rem 0;
}

.width-8r {
  width: 8rem;
}

.event-class {
  text-align: center;
}

.socket-status {
  color: $white-color;
  margin-bottom: 1.4rem !important;
}

.container {
  margin-top: 80px !important;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: $light-gray;
  width: 8px;
}

.smooth-modal {
  opacity: 0;
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);

  mat-dialog-container {
    overflow: hidden !important;
  }
}

.full-opacity {
  background-color: rgba(0, 0, 0, 1);
}

.mat-badge-content {
  top: 0px !important;
  right: -28px !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.p-0 {
  padding: 0 !important;
}

.me-8p {
  margin-inline-end: 8px;
}

.p-4 {
  padding: 1.5rem;
}

.px-2 {
  padding-inline: 8px;
}

.text-center {
  text-align: center;
}

.loader-wrapper {
  margin-top: 6rem;
  padding: 2rem;
}

.background-blur,
.cdk-overlay-dark-backdrop {
  -moz-backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
  -o-backdrop-filter: blur(6px) !important;
  backdrop-filter: blur(6px) !important;
  background-color: #000000a1 !important;
}

.upper-case {
  text-transform: uppercase;
}

mat-icon {
  font-family: "Material Icons" !important;
}

.back-nav {
  margin-inline-end: 8px;
  margin-bottom: 6px;
  cursor: pointer;
}

@media (max-width: 420px) {
  :root {
    font-size: 70%;
  }
}

@media (max-width: $medium-screen) {
  main {
    background-color: #232427;
  }
}

@import "./assets/styles/full-calendar.scss";
@import "./assets/styles/modal.scss";
@import "./assets/styles/controls.scss";

@media (min-width: 1680px) {
  .container {
    margin-top: 66px !important;
  }
}
